/* eslint-disable react/prop-types */
import type { QueryPost } from '@Types/Shared/Query';
import type { SEO } from '@Types/Shared/Seo';
import type { MenusType, SiteType } from '@Types/Shared/SiteDatas';

import type { AllBlocks } from '@/src/Types/Shared/Common';
import Blocks from '@/src/Views/Shared/Blocks/Blocks';
import Layout from '@/src/Views/Shared/Layout/Layout';

import { getBlocksUse } from '../Shared/lib';
import type { Translation } from '../Shared/Translations';

type Props = {
	blocks: AllBlocks[];
	site: SiteType;
	menus: MenusType;
	minos: any;
	translations: Translation[];
	post: QueryPost;
	seo: SEO;
};

export default function Front({ blocks, site, menus, minos, translations, seo }: Props) {
	return (
		<Layout
			blocks={getBlocksUse(blocks)}
			seo={seo}
			site={site}
			translations={translations}
			menus={menus}
			minos={minos}
		>
			<Blocks options={minos} blocks={blocks} />
		</Layout>
	);
}
